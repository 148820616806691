import $ from "jquery";
import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="dependent-select"
export default class extends Controller {
  static targets = ['main', 'select', 'mainSelect']


  change(event) {
    let url = this.mainTarget.dataset.url
    let optionSelected = $(event.currentTarget).val()
    if (Array.isArray(optionSelected))
      optionSelected = optionSelected.join()

    get(`${url}?category_id=${optionSelected}&template=select`, { responseKind: 'turbo-stream' })
  }
}

import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'


// Connects to data-controller="scroll"
export default class Scroll extends Controller {
  static targets = ['messages']
  static messages;

  connect() {
    Scroll.messages = this.messagesTarget
    let url = Scroll.messages.dataset.url;

    const observer = new MutationObserver((mutations) => {
      let bottomOfScroll = Scroll.messages.scrollHeight - Scroll.messages.clientHeight;
      for (let mutation of mutations) {
        // Check if nodes were added
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          let lastAddedNode = mutation.addedNodes[mutation.addedNodes.length - 3];

          // Check if the added node is the last child
          if (lastAddedNode === mutation.target.lastElementChild) {
            get(`${url}`, { responseKind: 'json' })
            Scroll.messages.scrollTop = bottomOfScroll;
          }
        }
      }
    });

    // Start observing changes on the target node
    observer.observe(document.getElementById(this.messagesTarget.dataset.messagesContainer), {
      childList: true, // Watch for changes in the child nodes
    });

    setTimeout(() => {
      let bottomOfScroll = this.messagesTarget.scrollHeight - this.messagesTarget.clientHeight;
      this.messagesTarget.scrollTop = bottomOfScroll;
    }, 500);
  }

  scrollToBottom() {
    // hack to wait for the render of messages content
    setTimeout(() => {
      this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight - this.messagesTarget.clientHeight;
    }, 500);
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  launch(event) {
    let url = event.currentTarget.dataset.url;
    navigator.clipboard.writeText(url);
  }

}


// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails";
import "@rails/request.js";
import "jquery";
import "./controllers";
import * as ActiveStorage from "@rails/activestorage";

import Rails from "@rails/ujs";

Rails.start();
ActiveStorage.start();

setInterval(() => {
  var contentHeight = document.body.offsetHeight;
  window.parent.postMessage({ type: "setHeight", height: contentHeight }, "*");
}, 1000);


document.addEventListener("turbo:before-fetch-request", (event) => {
  if (event.target.dataset["turboStream"] == "false") {
    event.detail.fetchOptions.headers["Accept"] = "text/html, application/xhtml+xml"
  }
})

document.addEventListener("turbo:load", function () {
  console.log("====================================");
  console.log("changed page marketplace");
  console.log("====================================");
  window.parent.postMessage({ type: "changeWindow" }, "*");
});

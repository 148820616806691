import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = []

  connect() {
    let scrollElementId = localStorage.getItem("scrollElementId")
    if (!scrollElementId) return
    console.log(scrollElementId)
    localStorage.removeItem("scrollElementId")
    window.parent.postMessage({ type: "scrollToElement", elementId: scrollElementId }, "*");
    document.getElementById(scrollElementId).scrollIntoView({ behaviour: 'smooth' });
  }

  loadPosition(event) {
    localStorage.setItem("scrollElementId", event.currentTarget.dataset.elementId)
    history.back();
  }
}

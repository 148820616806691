import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    view: String,
  }

  static targets = ['categoriesFieldInput', 'subCategoriesFieldInput']

  async connect() {
    // Add the controller functionality only on forms
    if (["edit", "new"].includes(this.viewValue)) {
      await this.onCategoryChange();
    }
  }


  async onCategoryChange(event) {
    console.log(this.categoriesFieldInputTarget.value);
  }

  async fetchSubCategoriesForCategories(categories) {
    if (!country) {
      return [];
    }

    const response = await fetch(
      `${window.Avo.configuration.root_path}/resources/categories/subcategories?category_ids=${categories}`
    );
    const data = await response.json();

    return data;
  }
}


import Dropdown from 'stimulus-dropdown'

// Connects to data-controller="dropdown"
export default class extends Dropdown {
  static targets = ['hidden', 'label']

  connect() {
    super.connect()
  }

  toggle(event) {
    super.toggle()
    let value = event.currentTarget.getAttribute('data-value')
    if (value !== null) {
      this.labelTarget.innerHTML = event.target.innerHTML
      this.hiddenTarget.setAttribute('value', value)
      this.hiddenTarget.dispatchEvent(new Event('change'))
    }
  }

  hide(event) {
    super.hide(event)
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
  }

  setError(event) {
    event.preventDefault()

    document.getElementById("flash_container").innerHTML = `<div class="flash" data-controller="flash">\
      <div class="flash__content flash__content--danger" data-flash-target="content" data-action="click->flash#hide">\
        <div class="flash__icon-field">\
          <div class="flash__icon flash__icon--danger">\
            <i class="fa-light fa-circle-xmark"></i> \
          </div>\
        </div>\
        <div class="flash__message">\
          <p class="flash__title">\
            Campo obrigatório\
          </p>\
          <div class="flash__flash-message">\
            ${event.currentTarget.dataset.label}\
          </div>\
        </div>\
      </div>\
    </div>`
  }
}
